.PagesSort
  grid-area: 2/1/3/2
  display: none
  width: 100%
  z-index: 5
  overflow: auto
  @include Scroll_Gray
  &__New_Item
    width: 100%
    min-height: 48px
    padding: 16px
    text-align: start
    background-color: $sol_blue
    color: $background
  &__Item
    // &.Overlay
    //   background-color: $background
    //   border-radius: 4px
    &_Header
      display: flex
      align-items: center
      // gap: 8px
      width: 100%
      // height: 48px
      // padding: 8px 16px
      border-width: 1px 0
      border-style: solid
      border-top-color: transparent
      border-bottom-color: $lines
      background-color: $background
      color: $primary_text
      text-align: start
      transition: background-color 200ms
      &.Overlay
        width: calc( 100% + 2px )
        border-width: 1px
        border-radius: 4px
        border-color: $lines
      &_text
        flex-grow: 1
        height: 100%
        padding: 10px 4px
      &_Icon
        flex-shrink: 0
        // width: 44px
        // height: 40px
        padding: 10px 4px 10px 16px
      &_Switch
        flex-shrink: 0
        display: flex
        height: 100%
        padding: 10px 16px 10px 4px
      &.Active
        background-color: $sol_blue20
  &__Overlay
    &_Header
      display: flex
      align-items: center
      width: 100%
      border-width: 1px
      border-style: solid
      border-color: $lines
      border-radius: 4px
      background-color: $background
      color: $primary_text
      text-align: start
      transition: background-color 200ms

@media (min-width: 1536px)
  .PagesSort
    grid-area: 1/1/2/2
    display: block
