.PVField
  &__Grid
    width: 100%
    height: fit-content
    display: grid
    grid-template-columns: 1fr auto 2fr
    gap: 40px
    &--Inputs
      @extend .PVField__Grid
      grid-template-columns: repeat(2, 1fr)
      grid-template-rows: repeat(7, auto)
      grid-auto-flow: column
      gap: 1rem