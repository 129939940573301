.ProjectSummary
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 1fr 53px
  width: 100%
  height: 100%
  background-color: $pills
  overflow: hidden
  &__Title
    display: flex
    justify-content: center
    align-items: center
    border-bottom: 1px solid $lines
  &__Content
    display: flex
    flex-direction: column
    // justify-content: space-between
    gap: 1rem
    padding: 2rem 0
    overflow: auto
  &__General_Data_Container
    width: 100%
    border-bottom: 1px solid $lines
  &__General_Data
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 1rem
    width: 100%
    height: max-content
    padding: 1rem 0.5rem 0
  &__General_Data_Item
    width: 100%
    height: fit-content
    display: grid
    grid-template-columns: auto 1fr
    gap: 5px
    align-items: center
  &__Container
    display: flex
    justify-content: space-between
    width: 969px
    max-width: 95vw
    margin: 0 auto
    padding: 1rem
    border-radius: 10px
    background-color: $background
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
  &__Fields-Container
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 16px
  &__Fields
    width: 100%
    height: fit-content
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 1rem
  &__Footer
    display: flex
    flex-direction: column
    gap: 0.5rem
    width: 100%
    height: 53px
    border-top: 1px solid $lines
    padding: 0.5rem 1rem
    background: $background
    &_Content
      display: flex
      justify-content: flex-end
      align-items: center
      gap: 8px
    .button-blue
      padding: 4px 16px !important

@media (max-width: 991px)
  .ProjectSummary
    &__General_Data
      grid-template-columns: repeat(2, 1fr)
      width: 100%
      max-width: 690px
    &__Container
      width: 100%
      max-width: 448px
    &__Fields
      &.Solution
        grid-template-columns: repeat(auto-fit, minmax(100%, 380px))
      &.System
        grid-template-columns: 1fr

@media (max-width: 767px)
  .ProjectSummary
    grid-template-rows: 1fr 75px
    &__Title
      height: 69px
    &__General_Data
      max-width: 448px
    &__Footer
      height: 75px
      &_Content
        justify-content: space-between
      button
        width: 100%
