.ShowCopyToClipboard
  position: fixed
  bottom: 0.5rem
  right: 1rem
  // left: 50vw
  // transform: translateX(-50%)
  z-index: 99999
  width: max-content
  padding: 1rem
  border-radius: 1rem
  background-color: $bg_primary
  color: $primary
  // box-shadow: 2px 2px 15px rgba(62, 62, 62, 0.2)
