.SubsidyTaxBenefits
  &__Percentages-Grid
    display: grid
    grid-template-columns: repeat(5, 1fr)
    gap: 8px
    &-Extra
      height: 100%
      display: grid
      grid-template-rows: repeat(5, 1fr)
      align-items: flex-end
      gap: 8px