.ManageTableModal
  display: flex
  flex-direction: column
  width: 100%
  &__Item
    padding: 3px 0
    display: grid
    grid-template-columns: 1fr 50px 50px
    border-bottom: 1px solid $lines
    justify-items: center
    &_Label
      justify-self: flex-start
    &_Button
      justify-self: center
      display: flex
      justify-content: center
      align-items: center
      width: 32px
      height: 32px
      padding: 8px
      border-radius: 5px
      background-color: $background
      color: $sol_blue
      &:hover
        background-color: $sol_blue10
