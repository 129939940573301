.AuthCard
  width: 340px
  padding: 16px
  border-radius: 10px
  box-shadow: $card_shadow
  display: flex
  flex-direction: column
  gap: 12px
  text-align: center
  &__Steps
    display: flex
    justify-content: center
    align-items: center
    gap: 10px
