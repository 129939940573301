.PageInfo
  grid-area: 2/1/3/2
  display: flex
  flex-direction: column
  gap: 16px
  width: 100%
  height: 100%
  padding: 16px
  overflow: auto
  isolation: isolate
  @include Scroll_Gray
  &__Card
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
    padding: 16px
    border-radius: 4px
    border: 1px solid rgba($primary_text, 15%)
    background-color: $background
    &_Header
      display: flex
      align-items: center
      gap: 8px
      &_Icon
        flex-shrink: 0
      &_Text
        flex-grow: 1
        font-size: 16px
        font-weight: 500
        color: $primary_text
    &_Subpages
      display: flex
      align-items: center
      gap: 8px
      &_options
        display: flex
        align-items: center
        gap: 2px

.inputTable
  display: flex
  align-items: center
  gap: 8px
  &_text
    flex-grow: 1

@media (min-width: 1536px)
  .PageInfo
    grid-area: 1/2/2/2
