.DropdownSearch
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 16px
  position: relative
  width: min-content
  border-radius: 10px
  background-color: $background
  color: $secondary_black
  text-align: start
  cursor: pointer
  &:hover
    background-color: $hover
  &.Active
    background-color: $hover
  &__Icon
    color: $secondary_text
  &__Show-Container
    position: absolute
    top: 0
    left: 0
    z-index: 11
    width: 150px
    height: auto
    padding: 10px
    border-radius: 10px
    background: $background
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
    opacity: 0
    text-align: left
    overflow: hidden auto
    @include Scroll_Blue
  &__Title
    // width: 100%
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    &-Container
      display: flex
      flex-direction: column
      width: 150px
      padding: 10px
  &__Show-Title-Container
    display: flex
    flex-direction: column
    padding-bottom: 10px
  &__Search
    margin-bottom: 10px
  &__Item
    width: 100%
    padding: 8px 10px
    border-radius: 10px
    cursor: pointer
    text-align: left
    &:hover
      background: $hover
    &.Active
      // @extend .Dropdown__Item
      background: $hover
      // color: $secondary_black
      // cursor: not-allowed
      &:hover
        background: $hover
  &__Amount-Buttons
    display: flex
    align-items: center
    gap: 15px

.DropdownSearch__Show-Container.enter
  opacity: 0
.DropdownSearch__Show-Container.enter-active
  opacity: 1
  transition: opacity 300ms
.DropdownSearch__Show-Container.enter-done
  opacity: 1
.DropdownSearch__Show-Container.exit
  opacity: 1
.DropdownSearch__Show-Container.exit-active
  opacity: 0
  transition: opacity 300ms

@media (max-width: 767px)
  .DropdownSearch
    padding: 0 5px
