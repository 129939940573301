.TransferSimulation
  display: grid
  grid-template-columns: 251px 1fr
  grid-template-rows: 57px 1fr 61px
  width: 100%
  height: 100%
  overflow-y: auto
  transition: grid-template-columns 300ms, grid-template-rows 300ms
  &--State2
    grid-template-columns: 0 1fr
  &--State3
    grid-template-columns: 0 1fr
  &__Path
    grid-column: 1/3
    grid-row: 1/2
    display: flex
    align-items: center
    gap: 16px
    width: 100%
    height: min-content
    padding: 10px 20px
    border-bottom: 1px solid $lines
    &_arrow_left
      cursor: pointer
      opacity: 1
      transform: opacity 200ms
      &__Disabled
        cursor: default
        opacity: 0
    &_arrow_right
      width: 16px
      height: 16px
      transform: rotateY(180deg)
      path
        fill: $secondary_text
  &__Simulation_Info
    grid-column: 1/2
    grid-row: 2/4
    overflow: hidden
    &_content
      display: flex
      flex-direction: column
      gap: 16px
      width: 250px
      height: 100%
      padding: 40px 20px
      border-right: 1px solid $lines
      overflow-y: auto
      &::-webkit-scrollbar
        width: 10px
        height: 10px
        &-thumb
          border: 1px solid transparent
          border-radius: 10px
          background-clip: content-box
          background-color: $secondary_text
          &:hover
            cursor: pointer
        &-track
          background-color: $background
    &_Title
      display: flex
      justify-content: center
      align-items: center
      gap: 0.5rem
      width: 100%
      padding: 4px
      border-radius: 5px
      background-color: $pills
    &_Item
      display: flex
      flex-direction: column
      gap: 16px
      &_Title
        padding: 10px
        border-bottom: 1px solid $secondary_text
      &_Content
        display: flex
        flex-direction: column
        gap: 6px
        position: relative
        padding: 10px 0
        border-bottom: 1px solid $secondary_text
        &_Tilte
          width: calc(100% - 26px)
          word-break: break-word
        &_Cancel
          position: absolute
          top: 9px
          right: 0
          padding: 5px 8px
          border-radius: 10px
          cursor: pointer
          &:hover
            background-color: $pills
    &_Users
      display: flex
      justify-content: center
      gap: 26px
      width: 100%
      padding: 16px
      border-top: 1px solid $pills
      border-bottom: 1px solid $pills
      &_Arrow
        align-self: center
  &__Content
    grid-column: 2/3
    grid-row: 2/3
    display: flex
    flex-direction: column
    gap: 8px
    width: 100%
    height: 100%
    padding: 1rem
    overflow: hidden
    &_Filters_Container
      display: flex
      justify-content: flex-start
      align-items: center
      flex-wrap: wrap
      gap: 16px
      padding-right: 32px
  &__Steps
    grid-column: 2/3
    grid-row: 3/4
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 16px
    width: 100%
    height: 100%
    padding: 10px 40px
    border-top: 1px solid $lines
    &_Dots_Container
      display: flex
      justify-content: center
      align-items: center
      gap: 8px
    &_Button_Icon
      width: 20px
      height: 20px

.TransferSimulation__Content.enter
  opacity: 0
.TransferSimulation__Content.enter-active
  opacity: 1
  transition: opacity 200ms
.TransferSimulation__Content.enter-done
  opacity: 1
.TransferSimulation__Content.exit
  opacity: 1
.TransferSimulation__Content.exit-active
  opacity: 0
  transition: opacity 200ms

@media (max-width: 767px)
  .TransferSimulation
    grid-template-columns: 1fr
    grid-template-rows: 41px 208px 1fr 51px
    &--State3
      grid-template-rows: 41px 0 1fr 51px
    &__Path
      grid-column: 1/2
      grid-row: 1/2
      justify-content: center
      &_arrow_left
        position: absolute
        left: 15px
        img
          width: 20px
          height: 20px
    &__Simulation_Info
      grid-column: 1/2
      grid-row: 2/3
      &_content
        gap: 4px
        width: 100%
        padding: 0.5rem 1rem 0 1rem
        border-right: none
        overflow: hidden
    &__Content
      grid-column: 1/2
      grid-row: 3/4
      gap: 16px
      overflow-y: auto
      &_Filters_Container
        padding-right: 0
    &__Steps
      grid-column: 1/2
      grid-row: 4/5
      justify-content: space-between
      padding: 10px 16px
