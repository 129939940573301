.DeleteSimulationModal
  &__warning_Icon
    width: 60px
    height: 60px
  &__Grid
    display: grid
    grid-template-columns: 1fr 1fr
    justify-items: center
    gap: 8px
    width: 100%
    max-width: 348px
    margin: auto
    &_Item
      width: 100%
      &_Title
        display: flex
        align-items: center
        gap: 8px
        color: $secondary_text
        word-break: break-word
        &_Icon
          width: 16px
          height: 16px
      &_Info
        margin-left: 24px
        word-break: break-word
