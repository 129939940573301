.PhoneNumberInput
  padding-left: 58px
  &__Selector
    display: flex
    gap: 0.25rem
    width: max-content
    padding: 11px 0.25rem 11px 0.5rem
    border-radius: 0.25rem
    background-color: $disabled
    &_Container
      position: absolute
      top: 0
      left: 1px
  &__Options_Container
    position: absolute
    bottom: 0
    left: 0
    z-index: 11
    width: max-content
    height: 250px
    padding: 10px
    border-radius: 10px
    background: $background
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
    text-align: left
    overflow: hidden auto
  &__Option
    width: 100%
    padding: 8px 10px
    border-radius: 10px
    cursor: pointer
    text-align: left
    &:hover
      background: $hover
    &.Active
      background: $hover

@media (max-width: 768px)
  .PhoneNumberInput
    &__Selector
      padding: 14px 0.25rem 14px 0.5rem
