.InputText
  display: flex
  flex-direction: column-reverse
  gap: 16px
  .ql-container
    border: 1px solid $lines !important
    border-radius: 5px
  .ql-toolbar
    display: flex
    flex-wrap: wrap
    gap: 4px
    padding: 0
    border: unset
    .ql-formats
      margin-right: 0
      + .ql-formats
        position: relative
        &::before
          content: ''
          position: absolute
          top: 0
          left: -3px
          height: 100%
          width: 1px
          background-color: $lines
