.UploadReport
  width: 100%
  height: 100%
  padding: 30px 16px 16px
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto 1fr
  justify-items: center
  align-items: center
  &__Logo
    height: 50px
    max-width: 100%
  &__File-Input-Container
    width: 100%
  &__File-Input
    display: grid
    grid-template-columns: 1fr
    justify-content: center
    align-items: center
    width: 100%
    max-width: 520px
    height: 240px
    border: 2px dashed $lightblue
    border-radius: 20px
    background: $bg_primary
    &--Active
      @extend .UploadReport__File-Input
      grid-template-columns: 1fr 23px
      // justify-items: start
      gap: 12px
      height: max-content
      padding: 12px 20px
      border: 2px solid $lightblue
  &__File-Name
    width: 100%
    text-align: left
    word-break: break-all
    text-overflow: ellipsis
    overflow: hidden
    // white-space: nowrap

@media (max-width: 767px)
  .UploadReport
    grid-template-rows: 1fr
