.Selector
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 12px
  position: relative
  width: 100%
  height: 34px
  border: 1px solid $lines
  border-radius: 4px
  padding: 6px 12px
  color: $input_text
  font-weight: 400
  transition: border 200ms
  &__Arrow
    transition: transform 200ms
    transform: rotateX(180deg)
    &.active
      transform: rotateX(0)
  &__Options
    display: none
    flex-direction: column
    gap: 4px
    position: absolute
    top: 120%
    z-index: 10
    width: 100%
    padding: 8px
    border: 1px solid $lines
    border-radius: 4px
    background-color: $background
    &.active
      display: flex
    &_Item
      display: flex
      text-align: start
      font-weight: 400
      padding: 8px
      border-radius: 5px
      &:hover
        background-color: $disabled
