.SimulationLoading
  display: flex
  justify-content: center
  position: absolute
  top: 0
  left: 0
  z-index: 99999
  width: 100%
  height: 100%
  background: rgba(255, 255, 255, 0.4)
  backdrop-filter: blur(2px)
