.button
  display: flex
  justify-content: center
  align-items: center
  gap: 8px
  max-width: 100%
  padding: 4px 16px
  border-radius: 100vw
  // font-size: 16px
  color: $background
  &_Icon
    path
      fill: $background
  &:disabled
    @extend .button
    background-color: $secondary_text
    color: $pills
    cursor: not-allowed
    &_Icon
      path
        fill: $pills
    &:hover
      background-color: $secondary_text
  &-blue
    @extend .button
    padding: 6px 32px !important
    background-color: $primary
    p
      color: $background
    &:hover
      background-color: $primary_dark
      color: $background
    &-disabled
      @extend .button-blue
      background-color: $secondary_text
      cursor: not-allowed
    &_Icon
      path
        fill: $background
  &-gray
    @extend .button
    padding: 6px 32px
    background-color: $secondary_text
    &:hover
      background-color: $secondary_black
  &-pill
    @extend .button
    background-color: $pills
    color: $primary_text
  &-green
    @extend .button
    background-color: rgba($completed, 0.3)
    color: $primary_text
  &_red
    @extend .button
    background-color: rgba($incomplete, 0.1)
    color: $red_text
    &:hover
      background-color: rgba($incomplete, 0.2)
  &-yellow
    @extend .button
    background-color: $yellow
    color: $primary_text
    &:hover
      background-color: $yellow_dark
  &-transparent
    @extend .button
    padding: 6px 32px
    color: $primary_text
    &:hover
      opacity: .8
      color: $primary_text
    &:disabled
      @extend .button-transparent
      background-color: unset
      color: $primary_text
      opacity: .8
      &:hover
        background-color: unset
  &_transparent_blue
    @extend .button
    background-color: $bg_primary
    color: $primary
    cursor: default
    p
      color: $primary
    &_Icon
      path
        fill: $primary
    &:hover
      background-color: #DBDDE4
  &_transparent_red
    @extend .button
    background-color: rgba($incomplete, 0.3)
    color: $primary_text
  &_transparent_yellow
    @extend .button
    background-color: rgba($yellow, 0.5)
    color: $primary_text
  &-disabled
    @extend .button
    background-color: $secondary_text
    cursor: not-allowed
  &__Hidden
    opacity: 0
    cursor: default !important

.button_defult
  padding: 4px 16px !important

.button.enter
  opacity: 0
.button.enter-active
  opacity: 1
  transition: opacity 200ms
.button.enter-done
  opacity: 1
.button.exit
  opacity: 1
.button.exit-active
  opacity: 0
  transition: opacity 200ms

.Button
  display: flex
  justify-content: center
  align-items: center
  gap: 8px
  max-width: 100%
  border-radius: 999px
  transition: color 300ms, background-color 300ms
  &.blue
    background-color: $sol_blue
    color: $white_text
    &:hover, &.active
      background-color: $sol_blue80
  &.blue10
    background-color: $sol_blue10
    color: $sol_blue
    &:hover, &.active
      background-color: $sol_blue20
  &.transparent
    background-color: transparent
    color: $primary_text
    &:hover, &.active
      background-color: $pills
  &.transparent_blue
    background-color: transparent
    color: $sol_blue
    &:hover, &.active
      background-color: $sol_blue10
  &.transparent_red
    background-color: transparent
    color: $red
    &:hover, &.active
      background-color: $red10
  &.white_blue
    background-color: $white_text
    color: $sol_blue
    border: 1px solid $sol_blue
    &:hover, &.active
      background-color: $sol_blue10
  &.green
    background-color: $sol_green
    color: $primary_text
    &:hover, &.active
      background-color: $sol_green30
  &.red
    background-color: $red
    color: $white_text
    &:hover, &.active
      background-color: $red80
  &.xs
    font-size: 12px
    padding: 4px 12px
  &.s
    font-size: 14px
    padding: 4px 12px
  &.m
    font-size: 16px
    padding: 4px 12px
  &.l
    font-size: 16px
    padding: 8px 16px
  &.hidden
    opacity: 0
    cursor: default
  &:disabled
    background-color: $secondary_text
    color: $pills
    cursor: default
    &:hover
      background-color: $secondary_text
