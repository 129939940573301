.GenerateReport
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 40px 1fr max-content
  height: calc(100vh - 62px)
  height: calc(100dvh - 62px)
  overflow: hidden
  &_Header
    grid-area: 1/1/1/1
    display: flex
    justify-content: center
    align-items: center
    gap: 8px
    border-bottom: 1px solid $background
    background-color: $sol_blue
    &_Angle
      transform: rotateX(180deg)
      transition: transform 200ms
      &.Active
        transform: rotateX(0deg)
  &_Actions
    grid-area: 3/1/4/2
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    gap: 8px
    padding: 9px 32px
    border-top: 1px solid rgba($primary_text, 15%)
    border-right: 1px solid rgba($primary_text, 15%)

@media (min-width: 768px)
  .GenerateReport
    grid-template-columns: 3fr 4fr
    &_Actions
      grid-template-columns: repeat(auto-fill, minmax(192px, 1fr))
      &_Preview
        display: none !important
      &_Download
        grid-column: 1/3

@media (min-width: 1280px)
  .GenerateReport
    grid-template-columns: 1fr 2fr
    &_Actions
      &_Download
        grid-column: auto

@media (min-width: 1536px)
  .GenerateReport
    grid-template-columns: 240px 1fr 2fr
    grid-template-rows: 1fr 50px
    &_Header
      display: none
    &_Actions
      grid-area: 2/1/3/3
      grid-template-columns: repeat(3, max-content)
      justify-content: end
