.Dashboard
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%
  height: 100%
  padding: 1rem
  overflow-y: auto
  &__Container_Special
    display: grid
    grid-template-columns: 251px 1fr
    width: 100%
    height: 100%
    overflow: hidden
    .Dashboard__Table_path_info_Info
      grid-column: 1/2
      grid-row: 1/2
  &__Filters_Container
    display: flex
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap
    gap: 16px
    // padding-right: 32px
    &_Mobile
      width: 100%
      display: flex
      align-items: center
      gap: 24px
  &__Table_path_info
    display: grid
    grid-template-columns: 251px 1fr
    grid-template-rows: 57px 1fr 61px
    width: 100%
    height: 100%
    overflow-y: auto
    transition: grid-template-columns 300ms, grid-template-rows 300ms
    &.No_Info
      grid-template-columns: 0 1fr
    &_Path
      grid-column: 1/3
      grid-row: 1/2
      display: flex
      align-items: center
      gap: 16px
      width: 100%
      height: min-content
      padding: 10px 20px
      border-bottom: 1px solid $lines
      &_Show
        opacity: 1
        transition: opacity 200ms
        &.Disabled
          cursor: default
          opacity: 0
        &:Disabled
          cursor: default
          opacity: 0
    &_Info
      grid-column: 1/2
      grid-row: 2/4
      overflow: hidden
      &_content
        display: flex
        flex-direction: column
        gap: 1rem
        width: 250px
        height: 100%
        padding: 40px 20px
        border-right: 1px solid $lines
        overflow-y: auto
        @include Scroll
    &_Content
      grid-column: 2/3
      grid-row: 2/3
      display: flex
      flex-direction: column
      gap: 8px
      width: 100%
      height: 100%
      padding: 1rem
      overflow: hidden
      &.Special
        grid-column: 2/3
        grid-row: 2/4
      &.Region
        grid-column: 2/3
        grid-row: 1/2
      &_Action
        display: flex
        align-items: center
        gap: 2rem
        width: 100%
        min-height: 2rem
        padding: 0 2rem
        border-left: 0px solid $primary
        border-radius: 10px
        margin-top: 8px
        background-color: $pills
    &_Steps
      grid-column: 2/3
      grid-row: 3/4
      display: flex
      justify-content: flex-end
      align-items: center
      gap: 16px
      width: 100%
      height: 100%
      padding: 10px 40px
      border-top: 1px solid $lines
      &_Dots_Container
        display: flex
        justify-content: center
        align-items: center
        gap: 8px
    &_Simulation_Count
      display: flex
      justify-content: center
      align-items: center
      gap: 0.5rem
      width: 100%
      padding: 4px
      border-radius: 5px
      background-color: $pills
    &_Confirm_Data
      display: flex
      justify-content: center
      gap: 26px
      width: 100%
      padding: 16px
      margin: 16px 0
      border-top: 1px solid $pills
      border-bottom: 1px solid $pills

.Dashboard__Table_path_info_Content_Action.enter
  opacity: 0
  height: 0px
  margin-top: 0px
.Dashboard__Table_path_info_Content_Action.enter-active
  opacity: 1
  height: 32px
  margin-top: 8px
  transition: height 200ms, margin-top 200ms, opacity 200ms 200ms
.Dashboard__Table_path_info_Content_Action.enter-done
  opacity: 1
  height: 32px
  margin-top: 8px
.Dashboard__Table_path_info_Content_Action.exit
  opacity: 1
  height: 32px
  margin-top: 8px
.Dashboard__Table_path_info_Content_Action.exit-active
  opacity: 0
  height: 0px
  margin-top: 0px
  transition: opacity 200ms, height 200ms 200ms, margin-top 200ms 200ms

@media (max-width: 767px)
  .Dashboard
    gap: 16px
    &__Container_Special
      display: grid
      grid-template-columns: 1fr
      grid-auto-rows: 169px 1fr
      width: 100%
      height: 100%
      overflow: hidden
    &__Filters_Container
      &_Mobile
        width: auto
    &__Table_path_info
      grid-template-columns: 1fr
      grid-template-rows: 48px auto 1fr 51px
      &.Info_2Row
        grid-template-rows: 48px 106px 1fr 51px
      &.Info_3Row
        grid-template-rows: 48px 136px 1fr 51px
      &.Info_4Row
        grid-template-rows: 48px 166px 1fr 51px
      &.Info_5Row
        grid-template-rows: 48px 196px 1fr 51px
      &.Info_6Row
        grid-template-rows: 48px 226px 1fr 51px
      &.No_Info
        grid-template-columns: 1fr
        grid-template-rows: 48px 0 1fr 51px
      &_Path
        grid-column: 1/2
        grid-row: 1/2
        justify-content: center
        &_Show
          &.arrow_left
            position: absolute
            left: 15px
            svg
              width: 20px
              height: 20px
      &_Info
        grid-column: 1/2
        grid-row: 2/3
        &_content
          gap: 4px
          width: 100%
          padding: 0.5rem 1rem 0 1rem
          border-right: none
          overflow: hidden
        &_mobile
          display: flex
          gap: 0.5rem
          overflow: auto hidden
      &_Content
        grid-column: 1/2
        grid-row: 3/4
        gap: 16px
        overflow-y: auto
        &.Special
          grid-column: 1/2
          grid-row: 3/5
        &.Region
          grid-column: 1/2
          grid-row: 2/3
        &_Action
          gap: 1rem
          padding: 0 1rem
      &_Steps
        grid-column: 1/2
        grid-row: 4/5
        justify-content: space-between
        padding: 10px 16px

@media (max-width: 575px)
  .Dashboard
    &__Filters_Container
      &_Mobile
        width: 100%
