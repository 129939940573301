.TableFiltersMobile
  // width: min-content
  border-radius: 10px
  background-color: $background
  color: $secondary_black
  text-align: start
  cursor: pointer
  &__Icon
    width: 17px
    height: 16px
    path
      fill: $secondary_text
  &__Title
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 8px
    padding: 4px
  &__Content
    display: flex
    flex-direction: column
    gap: 16px
    position: absolute
    top: 0
    left: 0
    z-index: 100
    width: 100%
    height: calc(100% - 62px)
    padding-bottom: 62px
    margin-top: 62px
    background-color: $background
    overflow-y: auto
    isolation: isolate
    &::-webkit-scrollbar
      width: 10px
      height: 10px
      &-thumb
        border: 1px solid transparent
        border-radius: 10px
        background-clip: content-box
        background-color: $secondary_text
        &:hover
          cursor: pointer
      &-track
        background-color: $bg_primary
    &_Title
      display: flex
      justify-content: center
      align-items: center
      position: sticky
      top: 0
      padding: 10px
      border-bottom: 1px solid $lines
      background-color: $background
      z-index: 10
      &_Button
        position: absolute
        left: 12px
        width: 20px
        height: 20px
        img
          width: 20px
          height: 20px
          vertical-align: baseline
    &_Clear
      display: flex
      align-items: center
      gap: 8px
      width: max-content
      padding: 10px 16px
      text-align: start
      &_Icon
        width: 12px
        height: 12px
        color: $primary
        transition: color 200ms
      p
        color: $primary
        transition: color 200ms
      &.Disabled
        p, .TableFiltersMobile__Content_Clear_Icon
          color: $secondary_text
    &_Item
      display: flex
      flex-direction: column
      gap: 16px
      &_Title
        width: 100%
        padding: 8px 16px
        background-color: $bg_primary
        text-align: center
      &_search
        padding: 0 16px
      &_Option_Selected
        width: max-content
        padding: 0 16px
        &_Icon
          width: 12px
          height: 12px
      &_Option
        display: flex
        justify-content: center
        align-items: center
        position: relative
        padding: 8px 16px
        &.Search
          justify-content: flex-start
        &.Active
          p
            font-weight: bold
        &_Icon
          position: absolute
          right: 16px

.TableFiltersMobile__Content.enter
  opacity: 0
.TableFiltersMobile__Content.enter-active
  opacity: 1
  transition: opacity 300ms
.TableFiltersMobile__Content.enter-done
  opacity: 1
.TableFiltersMobile__Content.exit
  opacity: 1
.TableFiltersMobile__Content.exit-active
  opacity: 0
  transition: opacity 300ms
