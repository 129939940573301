.create-simulation-main
  position: relative
  width: 100%
  height: 100vh
  height: 100dvh
  padding-top: 62px
  background: $pills
  display: grid
  grid-template-columns: 240px 1fr
  grid-template-rows: 0px 1fr 50px
  overflow: hidden
  isolation: isolate

.create-simulation-content
  grid-column: 2/3
  grid-row: 2/3
  width: 100%
  height: 100%
  padding: 1rem
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  gap: 1rem
  overflow: hidden auto
  @include scrollbar(8px, 1px)
  .step-loading-modal
    grid-column: 2/3
    grid-row: 2/3

.finish-simulation-content
  width: 100%
  height: calc(100vh - 62px) !important
  margin-top: 62px !important
  display: flex
  flex-direction: column
  overflow: hidden auto
  @include scrollbar(8px, 1px)

@media (max-width: 767px)
  .create-simulation-main
    grid-template-columns: 0px 1fr
    grid-template-rows: 99px 1fr 50px
