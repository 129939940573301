.Checkboxes__Container
  min-width: 180px

.Checkboxes
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 12px
  &.disabled
    .Checkboxes
      &__Option
        cursor: not-allowed
        &:hover
          .Checkboxes__Check
            transform: scale(0)
      &__Check
        display: none
  &.small
    .Checkboxes
      &__Option
        color: $input_black
      &__Circle
        width: 16px
        height: 16px
        border: 2px solid $input_black
      &__Check
        width: 8px
        height: 8px
        background: $input_black
  &__Option
    width: fit-content
    display: flex
    align-items: center
    gap: 12px
    &:hover
      .Checkboxes__Check
        transform: scale(1)
  &__Circle
    width: 20px
    height: 20px
    border-radius: 50%
    border: 3px solid $secondary_black
    display: flex
    justify-content: center
    align-items: center
  &__Check
    width: 10px
    height: 10px
    border-radius: 50%
    background: $secondary_black
    transform: scale(0)
    transition: transform .2s ease-in-out
    &.active
      transform: scale(1)
