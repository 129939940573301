.ImageLibraryCard
  &_Dropdown
    &_Containder
      display: grid
      grid-template-columns: 1fr
      grid-template-rows: max-content 0fr
      transition: grid-template-rows 300ms
      &.Active
        grid-template-rows: max-content 1fr
        .ImageLibraryCard_Dropdown_Header_Arrow
          transform: rotateX(0)
    &_Header
      display: flex
      justify-content: space-between
      align-items: center
      gap: 8px
      width: 100%
      padding: 6px 12px
      border: 1px solid $sol_blue
      border-radius: 5px
      color: $sol_blue
      &_Text
        flex-grow: 1
        text-align: start
        color: $sol_blue
      &_Arrow
        flex-shrink: 0
        transition: transform 300ms
        transform: rotateX(180deg)
    &_List
      &_Container
        height: 100%
        overflow: hidden
      &_Content
        display: flex
        flex-direction: column
        gap: 8px
        padding: 0
        border: 1px solid $sol_blue
        border-radius: 5px
        margin: 8px 0 0
      &_Item
        display: flex
        justify-content: space-between
        align-items: center
        gap: 16px
        padding: 8px
        border-radius: 5px
        cursor: pointer
        &:hover
          background-color: $disabled
        &_Image
          flex-shrink: 0
          width: 32px
          height: 32px
          border-radius: 4px
          object-fit: cover
        &_Info
          flex-grow: 1
          display: flex
          flex-direction: column
          gap: 2px
        &_Icon
          padding: 8px
          border-radius: 16px
          flex-shrink: 0
          &:hover
            background-color: $lines
