.RunningComplete
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 69px 1fr 53px
  width: 100%
  height: 100%
  background-color: $pills
  overflow: hidden
  &__Title
    display: flex
    align-items: center
    gap: 1rem
    width: 969px
    max-width: 95vw
    height: 100%
    margin: auto
    &_Container
      width: 100%
      border-bottom: 1px solid $lines
  &__Content
    display: flex
    flex-direction: column
    gap: 1rem
    padding: 1rem 0
    overflow: auto
  &__Container
    display: flex
    justify-content: space-between
    width: 969px
    max-width: 95vw
    margin: 0 auto
    padding: 1rem
    border-radius: 10px
    background-color: $background
  &__Fields-Container
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 1rem
  &__Fields
    width: 100%
    height: fit-content
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 1rem
    &.PPA
      padding: 0.5rem
      grid-template-columns: 1fr
      &.Disabled
        position: relative
        cursor: not-allowed
        &::after
          content: ''
          width: 100%
          height: 100%
          border-radius: 6px
          background: $primary_text
          opacity: .05
          position: absolute
          top: 0
          left: 0
          z-index: 1
  &__Selector
    width: 18px
    height: 18px
    border: 2px solid $primary_text
    border-radius: 30px
    display: flex
    justify-content: center
    align-items: center
    &::after
      content: ''
      width: 10px
      height: 10px
      background: $primary_text
      border-radius: 30px
      transform: scale(0)
      transition: transform 0.2s ease
    &--Selected
      @extend .RunningComplete__Selector
      &::after
        transform: scale(1)
  &__PPA
    display: grid
    grid-template-columns: 232px repeat(3, 1fr)
    gap: 0.5rem
    width: 100%
    overflow: hidden
    &_Fields
      width: 100%
      height: fit-content
      display: grid
      grid-template-columns: repeat(4, 1fr)
      gap: 1rem
    &_column
      display: grid
      grid-auto-rows: 50px
      &.Options
        border: 1px solid $lines_dark
        border-radius: 10px
      &.Selected
        border-color: $lightblue
    &_Titles
      display: flex
      align-items: center
      padding: 0.5rem
    &_Option
      display: flex
      align-items: center
      gap: 1rem
      padding: 0.5rem 1rem
      + .RunningComplete__PPA_Option
        border-top: 1px solid $pills
  &__Footer
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 8px
    position: fixed
    bottom: 0
    left: 0
    z-index: 10
    width: calc(100% - 8px)
    height: 53px
    padding: 10px 20px
    border-top: 1px solid $lines
    background: $background
    .button-transparent, .button-blue
      width: auto
      padding: 4px 16px !important
  &__Error
    width: fit-content
    height: fit-content
    border-radius: 12px
    padding: 1px 12px
    background: $error
    color: $primary_text !important
    font-weight: 500
    display: flex
    align-items: center
    transition: height .2s, color .2s, font-weight .2s

@media (max-width: 991px)
  .RunningComplete
    &__Title
      justify-content: center
      width: 100%
      max-width: 448px
    &__Container
      max-width: 448px
      // padding: 1rem
      // border-radius: 5px
      // box-shadow: $card_shadow
    &__Fields
      grid-template-columns: repeat(auto-fit, minmax(100%, 380px))
    &__PPA
      &_Fields
        grid-template-columns: repeat(auto-fit, minmax(100%, 380px))

@media (max-width: 767px)
  .RunningComplete
    grid-template-rows: 69px 1fr 96px
    &__Footer
      flex-direction: column
      height: 96px
      .button-transparent, .button-blue
        width: 100%
