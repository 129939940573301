.Home
  display: grid
  grid-template-columns: 1fr 1fr
  transition: grid-template-columns 300ms
  &__Logo
    grid-column: 1/2
    overflow: hidden
  &__Content
    grid-column: 2/3
    padding-top: 62px
  &__Header
    border-bottom: 1px solid $lines
  &__Card-Container
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 16px
    width: 90%
    max-width: 480px
    margin: 48px auto
  &__Card
    width: 100%
    border-radius: 10px
    padding: 16px
    background-color: $pills
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    gap: 20px
    color: $sol_blue
    &:hover
      color: $sol_blue
      background-color: $pills_dark
      .Button.blue
        background-color: $sol_blue80
    &_Icon
      width: 2rem
      height: 2rem
  &__Button
    grid-column: 1/3
    width: 100%
    display: flex
    align-items: center
    gap: 15px
    padding: 10px 16px
    border-radius: 10px
    background-color: $pills
    color: $sol_blue
    text-align: start
    &:hover
      color: $sol_blue
      background-color: $pills_dark
      .Button.blue
        background-color: $sol_blue80
  &__UserError
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: calc(100% - 48px)

@media (max-width: 991px)
  .Home
    grid-template-columns: 0fr 1fr
    &__Card-Container
      width: 94%
