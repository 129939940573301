.PageRender
  grid-area: 1/1/3/2
  display: none
  width: 100%
  height: 100%
  z-index: 10

@media (min-width: 768px)
  .PageRender
    grid-area: 1/2/4/3
    display: block
@media (min-width: 1536px)
  .PageRender
    grid-area: 1/3/3/4
    display: block
