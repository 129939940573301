.Table
  &.Simulations
    .Table__Header, .TableItem
      grid-template-columns: 40px minmax(100px, 1fr) minmax(125px, 1fr) repeat(4, minmax(170px, 2fr)) minmax(120px, 1fr) minmax(140px, 1fr) 55px
  &.Simulations_Extended
    .Table__Header, .TableItem
      grid-template-columns: 40px minmax(100px, 1fr) minmax(125px, 1fr) repeat(5, minmax(170px, 2fr)) minmax(120px, 1fr) minmax(140px, 1fr)
  &.Simulations_Extended_Action
    .Table__Header, .TableItem
      grid-template-columns: 40px minmax(100px, 1fr) minmax(125px, 1fr) repeat(5, minmax(170px, 2fr)) minmax(120px, 1fr) minmax(140px, 1fr) 55px
  &.Users
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(2, minmax(200px, 2fr)) repeat(2, minmax(140px, 1fr))
  &.Users_Extended
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(2, minmax(200px, 2fr)) repeat(2, minmax(140px, 1fr)) minmax(200px, 2fr) repeat(2, minmax(140px, 1fr))
  &.Users_Extended_Action
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(2, minmax(200px, 2fr)) repeat(2, minmax(140px, 1fr)) minmax(200px, 2fr) repeat(2, minmax(140px, 1fr)) 55px
  &.Users_Extended_Plus
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(2, minmax(200px, 2fr)) repeat(3, minmax(140px, 1fr)) minmax(200px, 2fr) repeat(2, minmax(140px, 1fr))
  &.Users_Extended_Plus_Action
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(2, minmax(200px, 2fr)) repeat(3, minmax(140px, 1fr)) minmax(200px, 2fr) repeat(2, minmax(140px, 1fr)) 55px
  &.Clients
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(2, minmax(170px, 2fr)) minmax(140px, 1fr) minmax(170px, 2fr) minmax(170px, 1fr) minmax(170px, 2fr) 100px
  &.Clients_Extended
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(3, minmax(170px, 2fr)) minmax(140px, 1fr) minmax(170px, 2fr) minmax(170px, 1fr) minmax(170px, 2fr) 100px
  &.Logs
    .Table__Header, .TableItem
      grid-template-columns: 40px minmax(140px, 2fr) repeat(2, minmax(170px, 3fr)) minmax(140px, 2fr) repeat(3, minmax(170px, 3fr))
  &.Organizations
    .Table__Header, .TableItem
      grid-template-columns: minmax(211px, 2fr) minmax(170px, 2fr) minmax(206px, 2fr) minmax(140px, 1fr) repeat(3, minmax(170px, 2fr)) 100px
  &.Regions
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(2, minmax(200px, 1fr)) minmax(350px, 2fr) 80px
  &.Offices
    .Table__Header, .TableItem
      grid-template-columns: 40px repeat(4, minmax(200px, 2fr)) minmax(170px, 1fr) 80px
