.UserInfoModal
  &__Data
    width: 100%
    border-bottom: 1px solid $lines
    padding: 1rem 0
    display: flex
    justify-content: space-around
    gap: 20px
  &__Icon
    width: 20px
    height: 20px
