.Loader
  display: inline-block
  position: relative
  width: 80px
  height: 80px
  &__Center
    position: absolute
    top: calc(50% - 15px)
    left: calc(50% - 15px)
    width: 30px !important
    height: 30px !important
    margin: unset !important
    border: unset !important
    border-radius: 30px
    background: $yellow
    animation: loading-center 1333ms cubic-bezier(0.5, 0, 0.5, 1) infinite !important

@keyframes loading-center
  0%
    transform: scale(.5)
  50%
    transform: scale(1)
  100%
    transform: scale(.5)

.common
  display: inline-block
  position: relative

.spinner
  width: 64px
  height: 64px
  display: inline-block
  position: relative
  text-align: initial
  color: $sol_blue

  &Container
    width: 100%
    height: 100%
    direction: ltr

  &Container.active
    animation: container-rotate 1568ms linear infinite

@keyframes container-rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.spinner-layer
  position: absolute
  width: 100%
  height: 100%

  .active &.layer-1
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both

@keyframes fill-unfill-rotate
  0%
    transform: rotate(0deg)
  12.5% 
    transform: rotate(135deg)

  25%
    transform: rotate(270deg)

  37.5% 
    transform: rotate(405deg)

  50%
    transform: rotate(540deg)

  62.5%
    transform: rotate(675deg)

  75%
    transform: rotate(810deg)

  87.5% 
    transform: rotate(945deg)

  100%
    transform: rotate(1080deg)

.gap-patch
  position: absolute
  box-sizing: border-box
  top: 0
  left: 45%
  width: 10%
  height: 100%
  overflow: hidden
  border-color: inherit

  & .circle
    width: 1000%
    left: -450%

.circle-clipper
  display: inline-block
  position: relative
  width: 50%
  height: 100%
  overflow: hidden
  border-color: inherit

  & .circle
    width: 200%

.circle
  box-sizing: border-box
  height: 100%
  border-style: solid
  border-color: inherit
  border-bottom-color: transparent !important
  border-radius: 50%
  animation: none

  &-clipper.left &
    border-right-color: transparent !important
    transform: rotate(129deg)

  &-clipper.right &
    left: -100%
    border-left-color: transparent !important
    transform: rotate(-129deg)

  .active &-clipper.left &
    animation: left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both

  .active &-clipper.right &
    animation: right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both

@keyframes left-spin
  0%
    transform: rotate(130deg)

  50%
    transform: rotate(-5deg)

  100%
    transform: rotate(130deg)

@keyframes right-spin
  0%
    transform: rotate(-130deg)

  50%
    transform: rotate(5deg)

  100%
    transform: rotate(-130deg)
