.SearchInput
  width: 100%
  height: 34px
  border: unset
  border: 1px solid $lines
  border-radius: 5px
  padding: 6px 12px
  padding: 6px 36px 6px 12px
  background: unset
  color: $input_text
  font-weight: 400
  transition: border .2s
  display: flex
  justify-content: flex-start
  align-items: center
  &::placeholder
    color: $sol_placeholder
    font-weight: 300
  &:focus
    ~ .SearchInput__Detail
      height: 3px
      opacity: 1
    ~ .SearchInput__Icon
      color: $sol_blue
    ~ .SearchInput__Tip
      color: $sol_blue
    &::placeholder
      color: unset
      opacity: 0
  &.Selected
    border-color: $sol_blue
  &--With-Icon
    @extend .SearchInput
    padding: 6px 6px 6px 48px
  &--Disabled
    width: 100%
    height: 34px
    padding: 6px 12px
    border-radius: 5px
    display: flex
    justify-content: flex-start
    align-items: center
    color: $primary_text
    font-weight: 400
    background-color: $disabled
    color: $sol_placeholder
    cursor: not-allowed
  &__Label
    color: $sol_placeholder
  &__Icon
    position: absolute
    left: 14px
    font-size: 20px
    top: calc(50% - 10px)
    transition: color .2s
    &-Disabled
      @extend .SearchInput__Icon
      left: 10px
  &__Error
    position: absolute
    left: 0
    top: 102%
    color: $incomplete
    font-weight: 400
    font-size: 12px
    &-Icon
      display: none
  &__List-Container
    width: 100%
    height: 34px
    cursor: pointer
    position: relative
    &--Disabled
      @extend .SearchInput__List-Container
      cursor: not-allowed !important
      background-color: $disabled
      color: $sol_placeholder
      .SearchInput__List-Icon
        cursor: not-allowed !important
  &__List
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    position: absolute
    top: calc(100% + 0.25rem)
    left: 0
    right: 0
    z-index: 10
    width: 100%
    max-height: calc(calc(30px * 4) + 1px)
    border: 1px solid $lines
    border-radius: 5px
    background: $background
    transition: color .2s
    overflow: hidden auto
    &::-webkit-scrollbar
      width: 10px
      &-thumb
        border: 2px solid transparent
        border-radius: 5px
        background-clip: content-box
        background-color: $sol_blue
    &-Item
      width: 100%
      height: fit-content
      padding: 6px 12px
      font-size: 12px
      color: $input_text
      text-align: left
      cursor: pointer
      transition: background-color .2s, color .2s
      &:hover
        background-color: $pills
        color: $sol_blue
    &-Item--Active
      @extend .SearchInput__List-Item
      color: $sol_blue
    &-Item--Disabled
      @extend .SearchInput__List-Item
      cursor: not-allowed !important
      &:hover
        background-color: $background
        color: $input_text
    &-Section
      width: 100%
      height: fit-content
      padding: 2px 12px
      background: $bg_primary
      text-align: left
      position: sticky
      top: 0
    &-Button
      width: 32px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      font-size: 20px
      position: absolute
      top: 0
      right: 0
      cursor: pointer
      img
        width: 20px
        height: 20px
        transition: transform .2s
      &--Active
        @extend .SearchInput__List-Button
        transition: transform .2s
        img
          transform: rotateX(180deg)
