.SimulationSwitch
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 48px 0fr
  border: 1px solid rgba(#000000, 10%)
  border-radius: 4px
  transition: grid-template-rows 300ms
  &.active
    grid-template-rows: 48px 1fr
  &__Button
    display: flex
    justify-content: space-between
    align-items: center
    padding: 8px
    border-radius: 4px
    transition: background-color 300ms
    &:hover
      background-color: $pills
  &__Content
    height: 100%
    overflow: hidden
