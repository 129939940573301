.InputFile
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 16px
  width: 100%
  padding: 8px
  border: 1px solid $lines
  border-radius: 5px
  background: unset
  color: $input_text
  font-weight: 400
  transition: border 200ms
  &:hover, &:focus, &:focus-visible
    border-color: $sol_blue
  &_Container
    display: flex
    flex-direction: column
    gap: 4px
    position: relative
    width: 100%
  &_Label
    font-size: 14px
    font-weight: 500
    color: $input_black
  &_Image
    flex-shrink: 0
    width: 42px
    height: 36px
    border-radius: 2px
    background-color: $lines_dark
    object-fit: cover
    &_Circle
      width: 42px
      height: 42px
      border-radius: 21px
      object-fit: cover
  &_Text
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 4px
  &_Button
    flex-shrink: 0
  &_Library
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
    padding: 16px
    border: 1px solid $lines
    border-radius: 4px
    &_Header
      display: flex
      align-items: center
      gap: 16px
