.SummaryField
  width: 100%
  display: grid
  grid-template-columns: 2rem 1fr
  column-gap: 0.25rem
  justify-items: center
  align-items: center
  border: 1px solid $lines_dark
  border-radius: 10px
  &.Half
    grid-template-columns: 1fr
    grid-template-rows: 2fr
    .SummaryField__Title
      padding: 0.25rem 1rem 0
    .SummaryField__Data
      grid-column: 1/2
      grid-row: 2/3
      padding: 0 1rem 0.25rem
  &.onlyValue
    display: flex
    border: none
    border-radius: 0
    border-top: 1px solid $pills
    .SummaryField__Data
      padding: 0.25rem 1rem
      border-width: 0
      border-radius: 0
  &__Title
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 6px
    width: 100%
    height: 100%
    padding: 0.25rem 0.5rem 0
    text-align: start
    overflow: hidden
    transition: border-color 0.2s ease
    p
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
  &__Data
    grid-column: 2/3
    grid-row: 2/3
    display: flex
    justify-content: flex-start
    align-items: center
    width: 100%
    height: 100%
    padding: 0 0.5rem 0.25rem
    transition: border-color 0.2s ease

@media (max-width: 1199px)
  .SummaryField
    grid-template-columns: 28px 1fr
    column-gap: 0
    &.Half
      // height: 50px
      grid-template-columns: 1fr
      .SummaryField__Data
        grid-column: 1/2
        grid-row: 2/3
    &__Title
      border-right: 1px solid $pills
      border-bottom: 0px solid $pills
      border-radius: 5px 5px 0 0
      overflow: auto
      p
        overflow: auto
        text-overflow: clip
        white-space: normal
    &__Data
      grid-column: 2/3
      grid-row: 2/3
      border-top: 0px solid $pills
      border-left: 1px solid $pills
      border-radius: 0 0 5px 5px
