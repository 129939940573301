$button-error: #FF0000CC

.SimulationFooter
  grid-column: 1/3
  grid-row: 3/4
  display: flex
  justify-content: flex-end
  align-items: center
  gap: 20px
  width: 100%
  height: 50px
  padding: 10px 20px
  border-top: 1px solid $lines
  background: $background
  &__Divisor
    width: 2px
    height: 100%
    border-radius: 5px
    background: $secondary_text
  &__Button-Error
    animation: error-submit 2.5s
    span
      animation: text-submit 2.5s

@keyframes error-submit
  0%
    background: $primary
  12%
    background: $button-error
  88%
    background: $button-error
  100%
    background: $primary

@keyframes text-submit
  0%
    transform: scale(1)
  6%
    transform: scale(0)
  12%
    transform: scale(1)
  88%
    transform: scale(1)
  94%
    transform: scale(0)
  100%
    transform: scale(1)

@media (max-width: 767px)
  .SimulationFooter
    justify-content: space-between
